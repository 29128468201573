<!-- 用户编辑弹窗 -->
<template>
  <el-dialog
    :title="isUpdate?'修改试题':'添加试题'"
    :visible="visible"
    width="60%"
    :destroy-on-close="true"
    :lock-scroll="false"
    custom-class="ele-dialog-form"
    @update:visible="updateVisible">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <!--      等级-->
      <el-form-item label="等级" prop="level_id">
        <el-select :span="18" v-model="ruleForm.level_id" placeholder="请选择等级">
          <el-option :span="18"
                     v-for="item in roleList"
                     :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <!--分数-->
      <el-form-item label="分数" prop="score">
        <el-col :span="18">
          <el-input v-model.number="ruleForm.score" placeholder="请输入分数"></el-input>
        </el-col>
      </el-form-item>
      <!--分数-->
      <el-form-item label="答题时长" prop="answer_time">
        <el-col :span="18">
          <el-input v-model.number="ruleForm.answer_time" placeholder="请输入答题时长"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item prop="question" label="问题" :rules="{required: true, message: '问题不能为空', trigger: 'blur'}">
        <el-col :span="18">
          <el-input v-model="ruleForm.question" placeholder="请输入问题"></el-input>
        </el-col>
      </el-form-item>
      <!--选项-->
      <el-form-item label="素材" prop="image">
        <el-upload
                class="avatar-uploader"
                :action="$http.defaults.baseURL+'upload/image/module_upload/image'"
                :show-file-list="true"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                :on-remove="handleRemove"
                list-type="picture-card"
                :file-list="ruleForm.fileList"
        >
          <i class="el-icon-plus"></i>
          <!--<img v-if="ruleForm.image" :src="$baseImgUrl+image"  v-model="ruleForm.image" class="avatar">-->
          <!--              <i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
        </el-upload>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button
        @click="resetForm">取消
      </el-button>
      <el-button
        type="primary"
        @click="save"
        :loading="loading">保存
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import TinymceEditor from '@/components/TinymceEditor';

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    // 父组件方法，关闭弹框
    openEdit: Function
  },
  components: {TinymceEditor},
  data() {
    return {
      // 表单数据
      init: {},
      // 表单验证规则
      ruleForm: Object.assign({level_id: '',
        score: '',
        answer_time: '',
        // 问题 - 选项
        question: '',
        url: [],
        fileList:[]
      }, this.data),
      // 等级下拉数据
      gradeSelection: [],
      rules: {
        score: [
          {required: true, message: '请输入分值', trigger: 'blur'}
        ],
        level_id: [
          {required: true, message: '请选择等级', trigger: 'blur'}
        ],
        answer_time: [
          {required: true, message: '请输入答题时长', trigger: 'blur'}
        ],
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      // 角色列表
      roleList: [],

    }
  },
  //mounted
  watch: {
    //deep: true,
    data() {
      if (this.data) {
        this.$http['get']('/Admin/sceneInfo/' + this.data.id).then(res => {
          this.data.fileList = [];
          let someArr = res.data.data.fileList;
          let newSomeArr = someArr.some( (item,i) =>{
            this.data.url.push(item.url)
            item.url = process.env.VUE_APP_BASE_URL + item.url;
            this.data.fileList.push(item)
          } )
          this.ruleForm = Object.assign({}, this.data);
          this.isUpdate = true;
        })
      }else{
        this.ruleForm = Object.assign({level_id: '',
          score: '',
          answer_time: '',
          question: '',
          url: [],
          fileList:[]
        }, this.data)
      }
    }
  },
  mounted() {
    this.queryRoles();  // 查询角色列表
    //this.queryImg();  // 查询角色列表
  },
  methods: {
    handleAvatarSuccess(res ,file, fileList) {
      this.ruleForm.url .push(res.data.path);
    },
    // 上传之前的回调
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    //删除图片
    handleRemove(file, fileList) {
      let url = this.ruleForm.url;
      url.some( (item,i) =>{
        if (item == file.path) {
          delete this.ruleForm.url[i];
        }
        if(file.response){
          if(item == file.response.data.path){
            delete this.ruleForm.url[i];
          }
        }

      } )
      /*for(var item in this.ruleForm.url){
        console.log(item)
        if (this.ruleForm.url[item] == file.path) {
          delete this.ruleForm.url[item];
        }else if(this.ruleForm.url[item] == file.response.data.path){
          delete this.ruleForm.url[item];
        }
      }
      console.log(this.ruleForm.url)*/
      //this.uploadDisabled = false;
    },
    /* 保存编辑 */
    save() {
      this.$refs['ruleForm'].validate((valid) => {
        //console.log(this.uploadFile)
        if (valid) {
          this.loading = true;
          this.$http[this.isUpdate ? 'put' : 'post'](this.isUpdate ? '/Admin/scene/' + this.ruleForm.id : '/Admin/scene', this.ruleForm).then(res => {
            this.loading = false;
            if (res.data.code === 200) {
              this.$message({type: 'success', message: res.data.msg});
              if (!this.isUpdate) {
                this.form = {};
              }
              this.updateVisible(false);
              this.$emit('done');
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
          });
        } else {
          return false;
        }
      });
    },
    // 关闭
    // 关闭
    resetForm(ruleForm) {
      // Object.assign(this.$data.ruleForm, this.$options.data().ruleForm)
      // this.$emit('update:visible', false);
      // 调用父组件方法 隐藏弹框
      this.openEdit(null)
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },
    /* 查询等级列表 */
    queryRoles() {
      this.$http.get('/Admin/level').then(res => {

        if (res.data.code === 200) {
          this.roleList = res.data.data;
          //console.log(this.roleList)
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(e => {
        this.$message.error(e.message);
      });
    },
  }
}
</script>

<style scoped>
</style>
